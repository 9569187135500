const environment = {
	bontu: {
		domain: 'https://api.bontu-dev.io/dev',
		apiKey: 'JEZ9yY9Jkd6n2FBEquTdC536sXthBpxv39tyJJB4'
	},
	production: true,
	hmr: false
};

export default environment;
