import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const mobileStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        height: '54px',
        background: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(91, 98, 104, 0.25)',
    },
    srPagoImg: {
        width: '96px',
        height: '32px',
        margin: '11px 0 auto auto'
    },
    navbarDivider: {
        width: '1px',
        height: '32px',
        margin: 'auto 12px auto 8px',
        background: '#DEE0E1'
    },
    bontuLogo: {
        width: '95px',
        height: '24px',
        margin: '16px auto auto 0'
    }
}));

const desktopStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        height: '108px',
        background: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(91, 98, 104, 0.25)',
        borderRadius: '12px 12px 0px 0px',
    },
    srPagoImg: {
        margin: '22px 14px auto auto'
    },
    navbarDivider: {
        width: '2px',
        height: '64px',
        margin: '22px 0 auto 0',
        background: '#DEE0E1'
    },
    bontuLogo: {
        margin: '31px auto auto 32px'
    }
}));

const Navbar = props => {
    const classes = props.isDesktop ? desktopStyles() : mobileStyles();

    return (
        <div className={classes.root}>
            <img className={classes.srPagoImg} src="https://static.wixstatic.com/media/97ce4e_2f4f59e8ea044fb8aae2fb1c7ea25356~mv2.png/v1/fill/w_420,h_263,al_c,lg_1,q_85/alphacredit-logo.webp" alt=""
                height="90" />
            <div className={classes.navbarDivider}></div>
            <svg className={classes.bontuLogo} width="190" height="48" viewBox="0 0 95 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.9578 23.0058C26.9465 23.0058 24.6752 22.2315 23.1438 20.7C21.6123 19.1514 20.8552 16.8284 20.8552 13.7312C20.8552 10.3758 21.6123 8.00122 23.1438 6.60744C24.6752 5.21367 26.9465 4.50818 29.9578 4.50818C31.9366 4.50818 33.6057 4.8007 34.965 5.40295C36.3416 6.00519 37.374 6.986 38.0967 8.32815C38.8022 9.6703 39.1636 11.477 39.1636 13.714C39.1636 16.8112 38.372 19.1342 36.7718 20.6828C35.1887 22.2487 32.9174 23.0058 29.9578 23.0058ZM29.9578 18.1878C30.5428 18.1878 31.0762 18.0329 31.558 17.7232C32.0398 17.4135 32.4012 16.9317 32.6593 16.2778C32.9174 15.6239 33.055 14.7636 33.055 13.7312C33.055 12.5095 32.9174 11.5803 32.6593 10.9264C32.4012 10.2725 32.0226 9.84237 31.558 9.61868C31.0762 9.39499 30.5428 9.27454 29.9578 9.27454C29.3211 9.27454 28.7705 9.39499 28.3231 9.61868C27.8757 9.84237 27.5316 10.2898 27.3079 10.9264C27.0842 11.5803 26.9637 12.5095 26.9637 13.7312C26.9637 15.3486 27.2218 16.4843 27.7208 17.1726C28.2543 17.8437 28.9942 18.1878 29.9578 18.1878Z" fill="#003B4A" />
                <path d="M42.2433 6.69339C42.6046 6.4869 43.1725 6.26321 43.9296 6.02231C44.6867 5.78141 45.547 5.55772 46.4762 5.35124C47.4226 5.14475 48.369 4.97268 49.3154 4.83503C50.2618 4.69737 51.1221 4.62854 51.8964 4.62854C53.3935 4.62854 54.7012 4.80061 55.8369 5.16196C56.9725 5.5061 57.8501 6.12556 58.4868 7.00312C59.1234 7.88068 59.4331 9.10238 59.4331 10.6854V22.4895H53.2386V12.1997C53.2386 11.7523 53.1698 11.3565 53.0493 11.0296C52.9289 10.7026 52.7396 10.4445 52.4987 10.2553C52.2578 10.066 51.9653 9.92832 51.6211 9.82508C51.277 9.73904 50.864 9.68742 50.4166 9.68742C49.9865 9.68742 49.5563 9.75625 49.1433 9.8767C48.7303 9.99715 48.4722 10.1176 48.4206 10.238V22.5067H42.2261V6.69339H42.2433Z" fill="#003B4A" />
                <path d="M71.3067 23.35C68.9665 23.35 67.2802 22.8338 66.2306 21.8186C65.181 20.8034 64.6475 19.358 64.6475 17.4996V9.79084H62.3418V5.19655H64.6475V0.499023H71.169V5.19655H74.6965V9.79084H71.169V16.9318C71.169 17.362 71.2895 17.6717 71.5304 17.8782C71.7713 18.0847 72.1154 18.1879 72.5628 18.1879C72.8725 18.1879 73.2511 18.1019 73.6468 17.947C74.0598 17.7921 74.404 17.5856 74.6965 17.3275V22.5413C74.1286 22.851 73.5608 23.0747 72.9758 23.1952C72.4079 23.2984 71.8573 23.35 71.3067 23.35Z" fill="#003B4A" />
                <path d="M86.8094 23.0059C85.1575 23.0059 83.6949 22.765 82.4044 22.2832C81.131 21.8014 80.1158 21.027 79.3759 19.9774C78.636 18.9278 78.2747 17.534 78.2747 15.8133V5.19653H84.4692V15.4347C84.4692 16.2435 84.6585 16.8629 85.0198 17.2931C85.3812 17.7061 86.0006 17.9298 86.861 17.9298C87.7213 17.9298 88.3408 17.7233 88.7021 17.2931C89.0635 16.8801 89.2528 16.2607 89.2528 15.4347V5.19653H94.9999V15.7961C94.9999 18.3599 94.26 20.2011 92.7802 21.3196C91.266 22.4552 89.2872 23.0059 86.8094 23.0059Z" fill="#003B4A" />
                <path d="M5.8332 17.5168V11.6836H11.6664V5.88482V5.8332H5.8332V0H2.80475C1.25612 0 0 1.25612 0 2.80475V5.8332V11.6664V17.4996V20.6141C0 22.1111 1.2217 23.3328 2.71872 23.3328H5.8332H11.6664H14.7981C16.2951 23.3328 17.5168 22.1111 17.5168 20.6141V17.4996H11.6836H5.8332V17.5168Z" fill="#003B4A" />
                <path d="M17.5163 11.6836H11.6831V17.5168H17.5163V11.6836Z" fill="#003B4A" />
                <path d="M15.4687 5.93638C15.245 5.88476 15.0213 5.85034 14.7804 5.85034H11.6831V11.6319V11.6835H17.5163V11.6319V8.70672C17.5163 7.41619 16.6559 6.29773 15.4687 5.93638Z" fill="#00C08B" />
            </svg>
        </div>
    );

}

export default Navbar;
